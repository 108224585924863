import React from 'react'
import { Box, Flex, Image, Text } from '@vtex/store-ui'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'

interface Props {
  feedback: {
    colorBase: string
    complementaryProducts: string[]
    subtone: string
    mainProducts: string[]
    mainBanner: string
    secondaryBanners: string[]
  }
  skinColor: string
}

const MainBanner = ({ feedback, skinColor }: Props) => {
  const { isMobile } = useDeviceDetect()

  const skinColorLetter = skinColor.split(' ')[1]

  const accessories =
    feedback.subtone === 'quente'
      ? 'mais com acessórios dourados'
      : feedback.subtone === 'frio'
      ? 'mais com acessórios prateados'
      : 'tanto com acessórios dourados quanto prateados'

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.textWrapper}>
        <span>Resultado do Quiz "Base ideal"</span>

        {isMobile && (
          <Box sx={{ mt: ['1rem'] }}>
            <Image
              src={`/arquivos/${feedback.mainBanner}.png`}
              sx={{
                width: ['20.375rem'],
                height: ['20.375rem'],
              }}
            />
          </Box>
        )}

        <h2>A dupla que você sempre procurou!</h2>

        <Text>
          <p>
            Baseado nas suas respostas, seu tom de base na Avon é{' '}
            <strong>{skinColor}</strong> e seu subtom é{' '}
            <strong>
              {feedback.subtone} (letra {skinColorLetter}).
            </strong>
          </p>
          <p>Sua pele tende a combinar e se destacar {accessories}</p>
          <p>Confira os produtos indicados para sua pele:</p>
        </Text>
      </Box>

      {!isMobile && (
        <Box>
          <Image
            src={`/arquivos/${feedback.mainBanner}.png`}
            sx={{
              width: ['25rem'],
              height: ['25rem'],
            }}
          />
        </Box>
      )}
    </Flex>
  )
}

export default MainBanner
