export const gabarito = {
  tomDePele: {
    escuro: {
      seIdentificaCom: {
        '540 F': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502433, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-540-A',
              secondaryBanners: ['resultado-540-B', 'resultado-540-C'],
              subtone: 'frio',
              colorBase: '540 F',
            },
            efeitoRadiante: {
              mainProducts: [189307806, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-540-A',
              secondaryBanners: ['resultado-540-B', 'resultado-540-C'],
              subtone: 'frio',
              colorBase: '540 F',
            },
            efeitoNatural: {
              mainProducts: [131502497, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-540-A',
              secondaryBanners: ['resultado-540-B', 'resultado-540-C'],
              subtone: 'frio',
              colorBase: '540 F',
            },
          },
        },
        '530 N': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502429, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-530-A',
              secondaryBanners: ['resultado-530-B'],
              subtone: 'neutro',
              colorBase: '530 N',
            },
            efeitoRadiante: {
              mainProducts: [189307806, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-530-A',
              secondaryBanners: ['resultado-530-B'],
              subtone: 'neutro',
              colorBase: '530 N',
            },
            efeitoNatural: {
              mainProducts: [131502503, 120608440],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-530-A',
              secondaryBanners: ['resultado-530-B'],
              subtone: 'neutro',
              colorBase: '530 N',
            },
          },
        },
        '520 NQ': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502419, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-520-A',
              secondaryBanners: ['resultado-520-B', 'resultado-520-C'],
              subtone: 'neutro quente',
              colorBase: '520 NQ',
            },
            efeitoRadiante: {
              mainProducts: [189307802, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-520-A',
              secondaryBanners: ['resultado-520-B', 'resultado-520-C'],
              subtone: 'neutro quente',
              colorBase: '520 NQ',
            },
            efeitoNatural: {
              mainProducts: [175470960, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-520-A',
              secondaryBanners: ['resultado-520-B', 'resultado-520-C'],
              subtone: 'neutro quente',
              colorBase: '520 NQ',
            },
          },
        },
        '510 N': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502400, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
            efeitoRadiante: {
              mainProducts: [189307798, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
            efeitoNatural: {
              mainProducts: [131502538, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
          },
        },
        '440 NF': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502403, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
            efeitoRadiante: {
              mainProducts: [157204735, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
            efeitoNatural: {
              mainProducts: [175470953, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
          },
        },
      },
    },
    'medio-escuro': {
      seIdentificaCom: {
        '510 N': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502400, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
            efeitoRadiante: {
              mainProducts: [189307798, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
            efeitoNatural: {
              mainProducts: [131502538, 120608434],
              complementaryProducts: [76179629, 131502781, 76179635],
              mainBanner: 'resultado-510-A',
              secondaryBanners: ['resultado-510-B'],
              subtone: 'neutro',
              colorBase: '510 N',
            },
          },
        },
        '440 NF': {
          efeitoIdeal: {
            efeitoMatte: {
              mainProducts: [131502403, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
            efeitoRadiante: {
              mainProducts: [157204735, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
            efeitoNatural: {
              mainProducts: [175470953, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-440-A',
              secondaryBanners: ['resultado-440-B'],
              subtone: 'neutro frio',
              colorBase: '440 NF',
            },
          },
        },
        '430 F': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502519, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-430-A',
              secondaryBanners: ['resultado-430-B', 'resultado-430-C'],
              subtone: 'frio',
              colorBase: '430 F',
            },
            efeitoMatte: {
              mainProducts: [131502439, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-430-A',
              secondaryBanners: ['resultado-430-B', 'resultado-430-C'],
              subtone: 'frio',
              colorBase: '430 F',
            },
            efeitoRadiante: {
              mainProducts: [189307794, 120608428],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-430-A',
              secondaryBanners: ['resultado-430-B', 'resultado-430-C'],
              subtone: 'frio',
              colorBase: '430 F',
            },
          },
        },
        '420 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502525, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-420-A',
              secondaryBanners: ['resultado-420-B'],
              subtone: 'quente',
              colorBase: '420 Q',
            },
            efeitoMatte: {
              mainProducts: [131502389, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-420-A',
              secondaryBanners: ['resultado-420-B'],
              subtone: 'quente',
              colorBase: '420 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307790, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-420-A',
              secondaryBanners: ['resultado-420-B'],
              subtone: 'quente',
              colorBase: '420 Q',
            },
          },
        },
        '410 NQ': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502512, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
            efeitoMatte: {
              mainProducts: [131502449, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
            efeitoRadiante: {
              mainProducts: [157204690, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
          },
        },
        '340 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502529, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
            efeitoMatte: {
              mainProducts: [131502386, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307786, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
          },
        },
      },
    },
    medio: {
      seIdentificaCom: {
        '410 NQ': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502512, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
            efeitoMatte: {
              mainProducts: [131502449, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
            efeitoRadiante: {
              mainProducts: [157204690, 120608422],
              complementaryProducts: [131502476, 131502781, 76179635],
              mainBanner: 'resultado-410-A',
              secondaryBanners: ['resultado-410-B'],
              subtone: 'neutro quente',
              colorBase: '410 NQ',
            },
          },
        },
        '340 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502529, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
            efeitoMatte: {
              mainProducts: [131502386, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307786, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-340-A',
              secondaryBanners: ['resultado-340-B'],
              subtone: 'quente',
              colorBase: '340 Q',
            },
          },
        },
        '330 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502547, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-330-A',
              secondaryBanners: ['resultado-330-B'],
              subtone: 'quente',
              colorBase: '330 Q',
            },
            efeitoMatte: {
              mainProducts: [131502406, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-330-A',
              secondaryBanners: ['resultado-330-B'],
              subtone: 'quente',
              colorBase: '330 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307782, 120608416],
              complementaryProducts: [131502473, 76688606, 76179635],
              mainBanner: 'resultado-330-A',
              secondaryBanners: ['resultado-330-B'],
              subtone: 'quente',
              colorBase: '330 Q',
            },
          },
        },
        '320 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502522, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-320-A',
              secondaryBanners: ['resultado-320-B'],
              subtone: 'quente',
              colorBase: '320 Q',
            },
            efeitoMatte: {
              mainProducts: [131502393, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-320-A',
              secondaryBanners: ['resultado-320-B'],
              subtone: 'quente',
              colorBase: '320 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307777, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-320-A',
              secondaryBanners: ['resultado-320-B'],
              subtone: 'quente',
              colorBase: '320 Q',
            },
          },
        },
        '310 NF': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502594, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
            efeitoMatte: {
              mainProducts: [131502442, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
            efeitoRadiante: {
              mainProducts: [157312151, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
          },
        },
        '240 N': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502542, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
            efeitoMatte: {
              mainProducts: [131502396, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
            efeitoRadiante: {
              mainProducts: [189307773, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
          },
        },
      },
    },
    'medio-claro': {
      seIdentificaCom: {
        '310 NF': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502594, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
            efeitoMatte: {
              mainProducts: [131502442, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
            efeitoRadiante: {
              mainProducts: [157312151, 120608410],
              complementaryProducts: [131502470, 76688606, 76179635],
              mainBanner: 'resultado-310-A',
              secondaryBanners: ['resultado-310-B', 'resultado-310-C'],
              subtone: 'neutro frio',
              colorBase: '310 NF',
            },
          },
        },
        '240 N': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502542, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
            efeitoMatte: {
              mainProducts: [131502396, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
            efeitoRadiante: {
              mainProducts: [189307773, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-240-A',
              secondaryBanners: ['resultado-240-B', 'resultado-240-C'],
              subtone: 'neutro',
              colorBase: '240 N',
            },
          },
        },
        '230 NF': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502509, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-230-A',
              secondaryBanners: ['resultado-230-B'],
              subtone: 'neutro frio',
              colorBase: '230 NF',
            },
            efeitoMatte: {
              mainProducts: [131502413, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-230-A',
              secondaryBanners: ['resultado-230-B'],
              subtone: 'neutro frio',
              colorBase: '230 NF',
            },
            efeitoRadiante: {
              mainProducts: [189307769, 120608404],
              complementaryProducts: [131502466, 76180155, 76179641],
              mainBanner: 'resultado-230-A',
              secondaryBanners: ['resultado-230-B'],
              subtone: 'neutro frio',
              colorBase: '230 NF',
            },
          },
        },
        '220 NQ': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502532, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-220-A',
              secondaryBanners: ['resultado-220-B', 'resultado-220-C'],
              subtone: 'neutro quente',
              colorBase: '220 NQ',
            },
            efeitoMatte: {
              mainProducts: [131502416, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-220-A',
              secondaryBanners: ['resultado-220-B', 'resultado-220-C'],
              subtone: 'neutro quente',
              colorBase: '220 NQ',
            },
            efeitoRadiante: {
              mainProducts: [189307765, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-220-A',
              secondaryBanners: ['resultado-220-B', 'resultado-220-C'],
              subtone: 'neutro quente',
              colorBase: '220 NQ',
            },
          },
        },
        '210 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502500, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
            efeitoMatte: {
              mainProducts: [131502436, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307761, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
          },
        },
        '134 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502535, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
            efeitoMatte: {
              mainProducts: [131502426, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307757, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
          },
        },
      },
    },
    claro: {
      seIdentificaCom: {
        '210 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502500, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
            efeitoMatte: {
              mainProducts: [131502436, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307761, 120608398],
              complementaryProducts: [131502463, 76180155, 76179641],
              mainBanner: 'resultado-210-A',
              secondaryBanners: ['resultado-210-B', 'resultado-210-C'],
              subtone: 'quente',
              colorBase: '210 Q',
            },
          },
        },
        '134 Q': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502535, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
            efeitoMatte: {
              mainProducts: [131502426, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
            efeitoRadiante: {
              mainProducts: [189307757, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-140-A',
              secondaryBanners: ['resultado-140-B'],
              subtone: 'quente',
              colorBase: '134 Q',
            },
          },
        },
        '130 F': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502482, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-130',
              secondaryBanners: [],
              subtone: 'frio',
              colorBase: '130 F',
            },
            efeitoMatte: {
              mainProducts: [131502445, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-130',
              secondaryBanners: [],
              subtone: 'frio',
              colorBase: '130 F',
            },
            efeitoRadiante: {
              mainProducts: [189307753, 120608392],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-130',
              secondaryBanners: [],
              subtone: 'frio',
              colorBase: '130 F',
            },
          },
        },
        '120 NQ': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502506, 120608385],
              complementaryProducts: [131502456, 76180155, 76179641],
              mainBanner: 'resultado-120-A',
              secondaryBanners: ['resultado-120-B', 'resultado-120-C'],
              subtone: 'neutro quente',
              colorBase: '120 NQ',
            },
            efeitoMatte: {
              mainProducts: [131502410, 120608385],
              complementaryProducts: [131502456, 76180155, 76179641],
              mainBanner: 'resultado-120-A',
              secondaryBanners: ['resultado-120-B', 'resultado-120-C'],
              subtone: 'neutro quente',
              colorBase: '120 NQ',
            },
            efeitoRadiante: {
              mainProducts: [189307749, 120608385],
              complementaryProducts: [131502456, 76180155, 76179641],
              mainBanner: 'resultado-120-A',
              secondaryBanners: ['resultado-120-B', 'resultado-120-C'],
              subtone: 'neutro quente',
              colorBase: '120 NQ',
            },
          },
        },
        '110 F': {
          efeitoIdeal: {
            efeitoNatural: {
              mainProducts: [131502486, 120608385],
              complementaryProducts: [131502456, 76180155, 76179641],
              mainBanner: 'resultado-110-A',
              secondaryBanners: ['resultado-110-B'],
              subtone: 'frio',
              colorBase: '110 F',
            },
            efeitoMatte: {
              mainProducts: [131502422, 120608385],
              complementaryProducts: [131502460, 76180155, 76179641],
              mainBanner: 'resultado-110-A',
              secondaryBanners: ['resultado-110-B'],
              subtone: 'frio',
              colorBase: '110 F',
            },
            efeitoRadiante: {
              mainProducts: [157204668, 120608385],
              complementaryProducts: [131502456, 76180155, 76179641],
              mainBanner: 'resultado-120-A',
              secondaryBanners: ['resultado-110-B'],
              subtone: 'frio',
              colorBase: '110 F',
            },
          },
        },
      },
    },
  },
}
