import React from 'react'
import { Box, Center, Spinner, Flex } from '@vtex/store-ui'
import ProductSummary from 'src/components/product/ProductSummary'
import ProductSlider from 'src/components/product/ProductSlider'
import { useQuery } from '@vtex/gatsby-theme-store'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import type {
  ProductsBySkuIdQueryQuery,
  ProductsBySkuIdQueryQueryVariables,
} from './__generated__/ProductsBySkuIdQuery.graphql'
import { ProductsBySkuIdQuery } from './__generated__/ProductsBySkuIdQuery.graphql'
import styles from './styles.json'

interface Props {
  skus: string[]
}

const ComplementaryProducts = ({ skus }: Props) => {
  const { isMobile } = useDeviceDetect()

  const { data } = useQuery<
    ProductsBySkuIdQueryQuery,
    ProductsBySkuIdQueryQueryVariables
  >({
    ...ProductsBySkuIdQuery,
    variables: { skus },
    revalidateOnMount: true,
  })

  return (
    <Box sx={styles.container}>
      <h2>Mais alguns produtos para chamar de seu</h2>
      <Flex sx={styles.productsWrapper}>
        {data === undefined ? (
          <Box sx={{ height: ['200px', '500px'] }}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : (
          <ProductSlider
            ProductSummary={ProductSummary}
            products={data.vtex.productsByIdentifier}
            pageSizes={[1, 2]}
            showArrows={isMobile}
            isStaticShelf
          />
        )}
      </Flex>
    </Box>
  )
}

export default ComplementaryProducts
