import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet'
import { SuspenseSSR } from '@vtex/store-ui'
import Layout from 'src/components/Layout'
import Result from 'src/views/encontresuacor/Result'
import { EncontreSuaCorContextProvider } from 'src/components/encontresuacor/context'

const Page: FC<PageProps> = ({ location: { search } }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf__bwUAAAAAAcD3DIJoQVhv8uMTkP08A32pHXW">
      <Helmet>
        <title>Escolha Sua Cor! - Quiz Base</title>
      </Helmet>
      <Layout>
        <EncontreSuaCorContextProvider>
          <SuspenseSSR fallback={null}>
            <Result search={search} />
          </SuspenseSSR>
        </EncontreSuaCorContextProvider>
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default Page
