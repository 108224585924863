import React, { useContext, useEffect } from 'react'
import { Box } from '@vtex/store-ui'
import MainBanner from 'src/components/encontresuacor/Result/MainBanner'
import MainProducts from 'src/components/encontresuacor/Result/MainProducts'
import ComplementaryBanner from 'src/components/encontresuacor/Result/ComplementaryBanner'
import ComplementaryProducts from 'src/components/encontresuacor/Result/ComplementaryProducts'
import Share from 'src/components/encontresuacor/Result/Share'

import { gabarito } from '../gabarito'
import { EncontreSuaCorContext } from '../../../components/encontresuacor/context'

interface Props {
  search: string
}

const extractAnswersFromSearch = (search: string) => {
  const answers = search.split('?answers=')[1].split('|')
  const skinTone = answers[0].split(':')[1]
  const skinColor = answers[1].split(':')[1].replace('%20', ' ')
  const jewel = answers[2].split(':')[1]
  const skinType = answers[3].split(':')[1]
  const makeup = answers[4].split(':')[1]

  return { skinTone, skinColor, jewel, skinType, makeup }
}

const Result = ({ search }: Props) => {
  const { answers, documentId } = useContext(EncontreSuaCorContext)
  const { skinTone, skinColor, makeup } = extractAnswersFromSearch(search)

  const feedback: {
    colorBase: string
    complementaryProducts: string[]
    subtone: string
    mainProducts: string[]
    mainBanner: string
    secondaryBanners: string[]
  } =
    gabarito.tomDePele[skinTone].seIdentificaCom[skinColor].efeitoIdeal[makeup]

  useEffect(() => {
    const allProducts: string[] = []

    allProducts.push(...feedback.mainProducts)
    allProducts.push(...feedback.complementaryProducts)

    const optionsAuthenticated = {
      method: 'PATCH',
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailCliente: answers.email,
        corClienteIndicado: answers.skinColor.split(' ')[0],
        produtosQuizIds: allProducts.toString(),
      }),
    }

    const optionsUnauthenticated = {
      method: 'POST',
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailCliente: answers.email,
        corClienteIndicado: answers.skinColor.split(' ')[0],
        produtosQuizIds: allProducts.toString(),
      }),
    }

    if (documentId) {
      const requestUrl = documentId
        ? `/api/io/safedata/QB/documents/${documentId}`
        : '/api/io/safedata/QB/documents'

      fetch(requestUrl, optionsAuthenticated).catch((error) =>
        console.error('Error while fetch data', error)
      )
    } else {
      fetch('/api/io/safedata/RQ/documents', optionsUnauthenticated).catch(
        (error) => console.error('Error while fetch data', error)
      )
    }
  }, [
    answers.email,
    answers.skinColor,
    feedback.mainProducts,
    feedback.complementaryProducts,
    documentId,
  ])

  return (
    <Box>
      <MainBanner feedback={feedback} skinColor={skinColor} />
      <MainProducts skus={feedback.mainProducts} />

      <Box sx={{ mt: ['4rem'] }}>
        <ComplementaryBanner banners={feedback.secondaryBanners} />

        <ComplementaryProducts skus={feedback.complementaryProducts} />
      </Box>

      <Share />
    </Box>
  )
}

export default Result
