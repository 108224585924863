import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Link } from '@vtex/store-ui'
import { Link as GatsbyLink } from 'gatsby'
import {
  FacebookFilledIcon,
  TwitterWithoutLinkIcon,
  WhatsAppIcon,
} from 'src/components/icons'

import styles from './styles.json'

const Share = () => {
  const [shareUrl, setShareUrl] = useState<string>('')

  useEffect(() => {
    const { search, origin, pathname } = window.location
    const encodedParams = encodeURIComponent(search)

    setShareUrl(`${origin}${pathname}${encodedParams}`)
  }, [setShareUrl])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Text as="h3" sx={styles.wrapper.title}>
          Gostou do quiz?
        </Text>
        <Text as="p" sx={styles.wrapper.subtitle}>
          Compartilhe o resultado com as amigas!
        </Text>
        <Flex sx={styles.wrapper.social}>
          <Box>
            <Link
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                shareUrl
              )}`}
              rel="noopener"
            >
              <TwitterWithoutLinkIcon />
            </Link>
          </Box>
          <Box>
            <Link
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
              )}`}
              rel="noopener"
            >
              <FacebookFilledIcon />
            </Link>
          </Box>
          <Box>
            <Link
              href={`https://api.whatsapp.com/send?text=Confira o resultado do meu quiz: ${encodeURIComponent(
                shareUrl
              )}`}
              rel="noopener"
            >
              <WhatsAppIcon />
            </Link>
          </Box>
        </Flex>
        <Box sx={styles.wrapper.buttonLink}>
          <GatsbyLink to="/encontresuacor" rel="noopener">
            Refazer
          </GatsbyLink>
        </Box>
      </Box>
    </Box>
  )
}

export default Share
