import React from 'react'
import { Box, Center, Spinner } from '@vtex/store-ui'
import ProductSummaryWhite from 'src/components/product/ProductSummaryWhite'
import ProductSlider from 'src/components/product/ProductSlider'
import { useQuery } from '@vtex/gatsby-theme-store'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'
import type {
  ProductsBySkuIdQueryQuery,
  ProductsBySkuIdQueryQueryVariables,
} from './__generated__/ProductsBySkuIdQuery.graphql'
import { ProductsBySkuIdQuery } from './__generated__/ProductsBySkuIdQuery.graphql'

interface Props {
  skus: string[]
}

const MainProducts = ({ skus }: Props) => {
  const { isMobile } = useDeviceDetect()

  const { data } = useQuery<
    ProductsBySkuIdQueryQuery,
    ProductsBySkuIdQueryQueryVariables
  >({
    ...ProductsBySkuIdQuery,
    variables: { skus },
    revalidateOnMount: true,
  })

  return (
    <Box sx={styles.container}>
      {data === undefined ? (
        <Box sx={{ height: ['200px', '500px'] }}>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        <ProductSlider
          ProductSummary={ProductSummaryWhite}
          products={data.vtex.productsByIdentifier}
          pageSizes={[1, 2]}
          showArrows={isMobile}
          isStaticShelf
        />
      )}
    </Box>
  )
}

export default MainProducts
