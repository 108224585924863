import React from 'react'
import { Box, Flex, Image } from '@vtex/store-ui'

import styles from './styles.json'

interface Props {
  banners: string[]
}

const ComplementaryBanner = ({ banners }: Props) => {
  return (
    <Flex sx={styles.container}>
      {banners.map((banner) => (
        <Box
          key={banner}
          sx={{
            width: ['20rem', '35%'],
            img: { borderRadius: ['50%'] },
          }}
        >
          <Image src={`/arquivos/${banner}.png`} />
        </Box>
      ))}
    </Flex>
  )
}

export default ComplementaryBanner
