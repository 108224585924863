

/**
 * Warning: This is an autogenerated file.
 *
 * Changes in this file won't take effect and will be overwritten
 */


// Operation related types
export type ProductsBySkuIdQueryQueryVariables = Exact<{
  skus: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ProductsBySkuIdQueryQuery = { vtex: { productsByIdentifier: Maybe<Array<Maybe<{ productName: Maybe<string>, linkText: Maybe<string>, brand: Maybe<string>, productReference: Maybe<string>, id: Maybe<string>, categoryTree: Maybe<Array<Maybe<{ name: Maybe<string> }>>>, productClusters: Maybe<Array<Maybe<{ id: Maybe<string>, name: Maybe<string> }>>>, properties: Maybe<Array<Maybe<{ name: Maybe<string>, originalName: Maybe<string>, values: Maybe<Array<Maybe<string>>> }>>>, items: Maybe<Array<Maybe<{ itemId: Maybe<string>, name: Maybe<string>, variations: Maybe<Array<Maybe<{ name: Maybe<string>, values: Maybe<Array<Maybe<string>>> }>>>, referenceId: Maybe<Array<Maybe<{ value: Maybe<string> }>>>, images: Maybe<Array<Maybe<{ imageUrl: Maybe<string>, imageText: Maybe<string> }>>>, sellers: Maybe<Array<Maybe<{ sellerId: Maybe<string>, commercialOffer: Maybe<{ spotPrice: Maybe<number>, availableQuantity: Maybe<number>, price: Maybe<number>, listPrice: Maybe<number>, maxInstallments: Maybe<Array<Maybe<{ value: Maybe<number>, numberOfInstallments: Maybe<number> }>>>, installments: Maybe<Array<Maybe<{ value: Maybe<number>, numberOfInstallments: Maybe<number>, interestRate: Maybe<number> }>>>, teasers: Maybe<Array<{ name: Maybe<string> }>> }> }>>> }>>> }>>> } };


// Query Related Code

export const ProductsBySkuIdQuery = {
  query: process.env.NODE_ENV === 'production' ? undefined : "query ProductsBySkuIdQuery($skus: [ID!]!) {\n  vtex {\n    productsByIdentifier(field: sku, values: $skus) {\n      id: productId\n      productName\n      linkText\n      brand\n      productReference\n      categoryTree {\n        name\n      }\n      productClusters {\n        id\n        name\n      }\n      properties {\n        name\n        originalName\n        values\n      }\n      items {\n        variations {\n          name\n          values\n        }\n        itemId\n        name\n        referenceId {\n          value: Value\n        }\n        images {\n          imageUrl\n          imageText\n        }\n        sellers {\n          sellerId\n          commercialOffer: commertialOffer {\n            maxInstallments: Installments(criteria: MAX_WITHOUT_INTEREST) {\n              value: Value\n              numberOfInstallments: NumberOfInstallments\n            }\n            installments: Installments(criteria: ALL) {\n              value: Value\n              numberOfInstallments: NumberOfInstallments\n              interestRate: InterestRate\n            }\n            availableQuantity: AvailableQuantity\n            price: Price\n            listPrice: ListPrice\n            spotPrice\n            teasers {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  sha256Hash: "17e6128a004234ebedcef60082a1b1b9374c04f0f62d86b1b0997fd350845886",
  operationName: "ProductsBySkuIdQuery",
}

